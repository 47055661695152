@font-face {
    font-display: swap;
    font-family: Lato;
    font-weight: 900;
    font-style: normal;
    src: url("../assets/fonts/latobold.woff2") format("woff2"), url("../assets/fonts/latobold.woff") format("woff");
}

@font-face {
    font-family: Lato-Italic;
    src: url("../assets/fonts/Lato-Italic.woff2") format("woff2"),
        url("../assets/fonts/Lato-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: "Open Sans";
    font-weight: 300;
    font-style: normal;
    src: url("../assets/fonts/opensans-light.woff2") format("woff2"), url("../assets/fonts/opensans-light.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Open Sans";
    font-weight: 400;
    font-style: normal;
    src: url("../assets/fonts/opensans-regular.woff2") format("woff2"), url("../assets/fonts/opensans-regular.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Open Sans";
    font-weight: 600;
    font-style: normal;
    src: url("../assets/fonts/opensans-semibold.woff2") format("woff2"), url("../assets/fonts/opensans-semibold.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Open Sans";
    font-weight: 700;
    font-style: normal;
    src: url("../assets/fonts/opensans-bold.woff2") format("woff2"), url("../assets/fonts/opensans-bold.woff") format("woff");
}