
.mask {
    width: 100%;
    height: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    z-index: 3000;
    transition: .6s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader,
.loader::before,
.loader::after {
    background: #009ccc;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}

.loader {
    color: #009ccc;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    transform: translateZ(0);
    animation-delay: -.16s;
}

.loader::before,
.loader::after {
    position: absolute;
    top: 0;
    content: "";
}

.loader::before {
    left: -1.5em;
    animation-delay: -.32s;
}

.loader::after {
    left: 1.5em;
}

@-webkit-keyframes load1 {

    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {

    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}


.hide-load {
    opacity: 0;
}