@import "./normalize.css";

@import "./fonts.css";

@import "./loader.css";

:root {
    --main-font: "Open Sans", "Arial", sans-serif;
    --second-font: Lato;
    --main-font-color: #212121;
    --second-font-color: #757575;
    --main-color: #009ccc;
    --btn-second-color: #ff9321;
    --background: #fafafa;
}

@custom-media --mobile-small (max-width: 29.999em);

@custom-media --mobile (min-width: 30em);

@custom-media --tablet (min-width: 48em);

@custom-media --desktop (min-width: 64em);

@custom-media --wide (min-width: 80em);

*::before,
*::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

input,textarea {
    outline: none;
}

input:-webkit-autofill {
    box-shadow: inset 0 0 0 1000px #fff;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: var(--main-font);
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    background-color: #fff;
    overflow-x: hidden;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

input[type = "number"]::-webkit-outer-spin-button,
input[type = "number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type = "number"] {
    -moz-appearance: textfield;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

button {
    cursor: pointer;
}


h4, h3, p {
    margin: 0;
}


.valid-ph, .valid-email, .valid-name {
    color: #2e7d32;
    font-weight: 600;
    font-size: 16px;
}

.error-ph, .error-email, .error-name {
    width: 16px;
    height: 32px;
    background-image: svg-load("../assets/img/svg/error.svg");
    background-size: 16px 32px;
}

.hide {
    display: none;
}


.iti__selected-flag {
    background: #eee;
    border-radius: 100px 0px 0px 100px;
}

.wrap {
    max-width: 1200px;
    margin: 0 auto;
    padding-right: 24px;
    padding-left: 24px; 
    box-sizing: border-box;
}

.title {
    margin: 0;
    font-family: var(--second-font);
    font-size: 24px;
    line-height: 28px;
    font-weight: 900;
    color: var(--main-font-color);
    font-style: normal;
    

    @media (--tablet) {
        font-size: 32px;
        line-height: 41px;
    }

    @media (--desktop) {
        font-size: 40px;
        line-height: 54px;
    }

    span {
        color: var(--accent-color);
    }
}

.button {
    position: relative;
    display: inline-block;
    padding: 12px 30px;
    font-family: var(--main-font);
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: box-shadow .3s;

    &:hover,
    &:active {
        box-shadow: 0 4px 6px var(--main-color);
    }

    &:focus {
        outline: none;
        box-shadow: 0 4px 6px var(--main-color);
    }

    &.button_primary {
        color: #fff;
        background: var(--main-color);
    }

    &:disabled {
        cursor: default;

        & span {
            opacity: 0;
        }
    }

    &.button_fluid {
        width: 100%;
    }
}

.logo {
    display: inline-block;
    width: 103px;
    height: 32px;
    background-image: svg-load("../assets/img/svg/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;


    @media (--mobile) {
        margin-right: 30px;
    }

    @media (--tablet) {
        width: 130px;
        height: 40px;
    }

    @media (--desktop) {
        margin-right: 25px;
    }
}

.list {
    padding: 0;
    list-style: none;

    li {
        color: var(--main-font-color);
        font-size: 14px;
        line-height: 20px;
        padding-left: 20px;
        background-image: svg-load("../assets/img/svg/check.svg");
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: left 6px;
        font-size: 14px;
        margin: 8px 0;


        @media(--tablet) {
            padding-left: 28px;
        }
    }

}

.content-item {
    margin-top: 24px;

    @media(--desktop) {
        margin-top: 0;
        display: flex;
        
    }

    &__header {

        & h3 {
            font-weight: 900;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 8px;
            font-family: var(--second-font);
        }

    }

    &__body {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }

    &__image {
        margin-bottom: 12px;

        & img {
            width: 56px;
            height: 56px;
    
            @media(--tablet) {
                width: 80px;
                height: 80px;
            }
        }

        @media(--desktop) {
            margin-bottom: 0;
        }
    }

    & .image-left {

        @media(--desktop) {
            margin-right: 24px;
        }
    }
}

.bar {
    position: relative;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(52, 65, 84, .1);

    @media (--tablet) {
        box-shadow: none;
    }

    @media (--desktop) {
        position: fixed;
    }

    & .bar__wrap {
        display: flex;
        justify-content: space-between;
        padding-top: 12px;
        padding-bottom: 12px;

        @media (--tablet) {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }

    & .bar__logo {
        display: flex;
        flex-direction: column;

        @media(--mobile) {
            flex-direction: row;
            align-items: center;
        }
    }

    & .bar-country {
        display: none;
        border-left: 1px solid #e0e0e0;

        @media(--desktop) {
            display: block;
        }

        &__title {
            width: 120px;
            padding-top: 15px;
            font-size: 12px;
            color: var(--main-font-color);
            font-weight: 500;
    
            @media (--desktop) {
                width: 190px;
                font-size: 14px;
                line-height: 20px;
                padding-left: 24px;
                padding-top: 0;
            }
        }
    }

    & .bar__button {
        height: 40px;
        padding: 0 15px;
        font-size: 12px;
        font-weight: 600;

        @media (--tablet) {
            height: 40px;
            padding: 0 24px;
            font-size: 14px;
        }
    }

    & .bar__side {
        display: flex;
        align-items: center;
    }

    & .bar__phone {
        position: relative;
        display: none;
        margin-right: 148px;
        padding-left: 50px;

        @media (--tablet) {
            display: block;
        }

        & a {
            font-family: var(--second-font);
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            color: var(--main-font-color);
        }

        & span {
            display: block;
            margin-right: 5px;
            color: var(--second-font-color);
            cursor: pointer;
            opacity: .5;
            transition: opacity .3s;
            font-size: 14px;
            line-height: 20px;

            &:hover {
                opacity: 1;
            }
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            background-repeat: no-repeat;
        }

        &::before {
            left: -15px;
            width: 44px;
            height: 44px;
            margin-top: -20px;
            background-color: transparent;
            border-radius: 50%;
            box-shadow: 0 1px 12px rgba(0, 0, 0, .15);
        }

        &::after {
            left: 2px;
            width: 10px;
            height: 15px;
            margin-top: -7.5px;
            background-image: svg-load("../assets/img/svg/phone.svg");
            background-size: 10px 15px;
        }
    }
}

.button-container {

    @media(--desktop) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }

    &__text {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;

        @media(--tablet) {
            font-size: 16px;
            line-height: 24px;
            max-width: 318px;
        }

        @media(--desktop) {
            margin-bottom: 0;
        }
    }
}

.price {
    font-family: var(--second-font);

    &-old {
        color: var(--second-font-color);
        text-decoration-line: line-through;
        font-size: 18px;
        line-height: 24px;
        margin: 16px 0 8px 0;

        @media(--tablet) {
            font-size: 24px;
            line-height: 32px;
            margin: 32px 0 8px 0;
        }
    }

    &-new {
        color: #212121;
        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        margin: 8px 0 16px 0;

        @media(--tablet) {
            font-size: 32px;
            line-height: 40px;
        }

        & span {
            color: #f44336;
        }
    }
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        max-width: 850px;
        margin-bottom: 16px;
    
        @media(--tablet) {
            font-weight: 900;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 32px;
        }
    }
}

.intro {
    color: var(--main-font-color);
    background-color: #fff738;

    & .intro__wrap {
        z-index: 1;
        padding: 24px 15px 40px;

        @media(--desktop) {
            padding: 150px 15px 115px;
        }
        
        position: relative;
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            height: 96%;
            bottom: 0;
            z-index: -1;
            width: 150%;
            background: url("../assets/img/intro-girl.jpg") center bottom/contain no-repeat;


            @media (--tablet) {
                height: auto;
                width: 100%;
                top: 125px;
                right: -13%;
                max-width: 552px;
            }

            @media(--desktop) {
                right: 0;
            }
        }
    }

    & .intro__inner {
        max-width: 376px;

        @media (--tablet) {
            margin: auto 0;
            max-width: 576px;
        }

        .button {
            height: 40px;
            padding: 0px 30px;

            @media (--mobile) {
                height: 48px;
                padding: 12px 30px;
            }
        }
    }

    & .intro__text {
        font-weight: 900;
        font-size: 14px;
        line-height: 16px;
        font-family: var(--second-font);
        max-width: 198px;
        margin-top: 8px;

        @media(--tablet) {
            max-width: none;
            font-size: 24px;
            line-height: 32px;
            margin-top: 32px;
        }

        & span {
            background: #fff738;
        }
    }

    & .intro__title {
        position: relative;
        font-family: var(--second-font);
        font-style: normal;
        font-size: 28px;
        line-height: 32px;
        max-width: 202px;
        font-size: 28px;

        @media (--mobile) {
            max-width: none;
            font-size: 30px;
            line-height: 36px;
        }

        @media (--tablet) {
            font-size: 48px;
            line-height: 48px;


            & span:last-child {
                display: inline-flex;
                flex-wrap: wrap;
            }
        }

        &::before {
            top: -50px;
            left: 220px;
        }

        & span:last-child {
            margin-top: 4px;
            background: #fff738;

            @media(--tablet) {
                margin-top: 8px;
            }
        }
    }

    & .intro__desc {
        color: #729514;
        font-family: Lato-Italic;
        font-size: 16px;
        line-height: 24px;
        font-weight: 900;
        margin: 0 0 4px 0;
        letter-spacing: .5px;
        font-style: italic;
        display: block;
        white-space: nowrap;
        position: relative;
        z-index: 3;
        margin: 0;

        @media(--tablet) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    & .intro__list {
        margin-bottom: 16px;
        margin-top: 0;

        @media(--desktop) {
            margin-bottom: 40px;
        }

        & li {
            line-height: 16px;
            background-position: left 4px;

            @media (--tablet) {
                font-size: 16px;
                margin: 15px 0;
                line-height: 24px;
            }

            @media(--desktop) {
                background-size: 16px;
            }
        }


    }
}

.container__item {
    display: grid;
    grid-template-columns: .1fr 1fr;
    grid-template-rows: 1fr;
    gap: 4px 12px;
    align-items: start;
    grid-template-areas: "aside header" "aside body";
    margin-bottom: 20px;

    &-header {
        grid-area: header;
    }

    &-aside {
        grid-area: aside;
        color: #fff;
        font-weight: 900;
        font-size: 18px;
        line-height: 28px;
        font-family: var(--second-font);
        background: var(--main-color);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(--tablet) {
            width: 64px;
            height: 64px;
            font-weight: 900;
            font-size: 24px;
            line-height: 32px;
        }
    }

    &-body {
        grid-area: body;
        font-size: 14px;
        line-height: 20px;

        @media(--tablet) {
            font-size: 16px;
            line-height: 24px;
        }

        @media(--desktop) {
            max-width: 412px;
        }
    }

}

.form {
    background: var(--main-color);
    padding: 40px 0;
    box-sizing: border-box;

    @media(--wide) {
        padding: 66px 0; 
    }

    &__wrap {
        width: 100%;
    }

    & .button {
        background: var(--btn-second-color);
        box-sizing: border-box;
        padding: 12px 0;
        white-space: nowrap;

        &:hover,
        &:active {
            box-shadow: 0 4px 6px var(--btn-second-color);
        }

        &:focus {
            outline: none;
            box-shadow: 0 4px 6px var(--btn-second-color);
        }
    }

    &__input {
        background: #fff;
        border-radius: 24px;
        border: 1px solid #fff;
        padding: 12px 20px;
        max-height: 48px;
    }

    & input {
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
    }

    & .input__wrap {
        position: relative;

        & .check {
            top: 20%;
            position: absolute;
            right: 10%;
        }

        & .iti__country-list--dropup {
            width: 270px;
        }
    }

    & .iti {
        width: 100%;
    }

    & form {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 24px;

        @media(--tablet) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 24px;
        }

        @media(--desktop) {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1fr;
            grid-row-gap: 0px;
        }
    }
}

.consultants {
    background: var(--background);
    padding: 40px 0;

    &__title {
        text-align: center;
        margin-bottom: 24px;

        @media(--desktop) {
            margin-bottom: 104px;
        }
    }

    &__inner {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 24px;

        @media(--desktop) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 24px;
            grid-row-gap: 0px;
        }
    }

    &__item {
        background: #fff;
        border-radius: 4px;
        padding: 24px 16px;
        display: grid;
        grid-template-columns: .5fr 2fr;
        grid-template-rows: 1fr;
        gap: 16px 8px;
        grid-template-areas: "avatar title" " text text";
        font-family: var(--main-font);

        @media(--desktop) {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 24px 32px;
        }

        & .item__text {
            grid-area: text;
            font-size: 12px;
            line-height: 16px;

            @media(--mobile) {
                font-size: 14px;
                line-height: 20px;
            }

            @media(--tablet) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        & .item__title {
            grid-area: title; 
            font-size: 14px;
            line-height: 18px;
            align-self: center;

            @media(--mobile) {
                font-size: 16px;
                line-height: 24px;
            }

            @media(--tablet) {
                font-size: 24px;
                line-height: 32px;
            }

            @media(--desktop) {
                text-align: center;
                margin-top: 80px;
                margin-bottom: 20px;
            }
        }

        & .item__avatar {
            grid-area: avatar;
            width: 48px;
            height: 48px;
            align-self: center;
            justify-self: center;
            border-radius: 50%;

            @media(--mobile) {
                width: 60px;
                height: 60px;
            }

            @media(--tablet) {
                width: 80px;
                height: 80px;
            }

            @media(--desktop) {
                position: absolute;
                top: -12%;
                width: 120px;
                height: 120px;
            }
        }
    }
}

.descriptions {
    padding: 40px 0;
    background: var(--background);
    
    @media(--desktop) {
        padding: 80px 0;
    }

    &__wrap {

        @media(--desktop) {
            padding: 0 40px;
        }

        @media(--wide) {
            padding: 0 72px;
        }
    }

    &__header {
        text-align: center;
    }

    & .title {
        margin-bottom: 24px;

        @media(--desktop) {
            margin-bottom: 40px;
        }
    }

    &__body {

        @media(--desktop) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 72px;
            grid-row-gap: 0px; 
        }

        & .list {
            margin-bottom: 24px;
        }
    }
}

.results {
    padding: 40px 0;

    @media(--desktop) {
        padding: 80px 0; 
    }

    &__header {
        text-align: center;
        margin-bottom: 32px;

        @media(--desktop) {
            margin-bottom: 40px;
        }
    }

    &__body {

        @media(--desktop) {
            display: flex;
            justify-content: space-between;

            & .content-item {
                display: flex;
                flex-direction: column;
                flex-basis: 225px;

                & img {
                    width: 88px;
                    height: 88px;
                }

                @media(--wide) {
                    flex-basis: 264px;
                } 

                &__image {
                    margin-bottom: 24px;
                }
            }
        }       
    }
}

.labor {
    background: var(--main-color);
    color: #fff;
    padding: 40px 0;
    position: relative;

    @media(--desktop) {
        padding: 88px 0;
    }

    &__wrap {

        @media(--desktop) {
            display: flex;
            justify-content: space-between;
        }

        @media(--wide) {
            justify-content: flex-end;
        }
    }

    &__inner {

        @media(--wide) {
            max-width: 552px;

            &::before {
                content: "";
                position: absolute;
                right: 58%;
                width: 41%;
                height: 100%;
                bottom: 0;
                z-index: 2;
                background: url("../assets/img/labor-man.jpg") bottom/contain no-repeat;

                @media(--wide) {
                    max-width: 666px;
                    right: 50%;
                    width: 100%;
                }
            }
        }
    }

    &__header {
        text-align: center;
        margin-bottom: 24px;

        @media(--desktop) {
            margin-bottom: 48px;
            text-align: start;
        }
    }

    & .title {
        color: #fff;
    }

    &__body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 16px;

        @media(--desktop) {
            grid-column-gap: 28px;
            grid-row-gap: 24px;
        }
    }

    &__content {
        font-family: var(--second-font);

        &-header {
            font-weight: 900;
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 4px;
        }

        &-body {
            font-weight: 900;
            font-size: 14px;
            line-height: 16px;
            opacity: .72;

            @media(--desktop) {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media(--desktop) {
            align-items: baseline;
        }

        & .button {
            background: var(--btn-second-color);

            &:hover,
            &:active {
                box-shadow: 0 4px 6px var(--btn-second-color);
            }

            &:focus {
                outline: none;
                box-shadow: 0 4px 6px var(--btn-second-color);
            }
        }
    }

    &__text {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        margin: 32px 0 27px 0;

        @media(--desktop) {
            font-weight: 900;
            font-size: 18px;
            line-height: 28px;
            text-align: start;
            margin: 40px 0 24px 0;
        }
    }
}

.service {
    background: #eaf7fb;
    padding: 40px 0;
    position: relative;
    z-index: 1;

    @media(--desktop) {
        padding: 80px 0; 
    }

    &__wrap {

        @media(--tablet) {

            &::after {
                content: "";
                position: absolute;
                top: 129px;
                right: -26%;
                bottom: 0;
                z-index: -1;
                width: 100%;
                background: url("../assets/img/service-girl.jpg") center bottom/contain no-repeat;
    
                @media(--desktop) {
                    top: 61px;
                    right: -27%;
                }
    
                @media (--wide) {
                    right: -17%;
                }
            }
        }
    }

    &__header {
        text-align: center;

        @media(--desktop) {
            text-align: start;
        }
    }

    &__inner {

        @media(--desktop) {
            max-width: 629px;
        }
    }

    &__meta {
        font-size: 14px;
        line-height: 20px;
        margin-top: 16px;

        @media(--desktop) {
            margin-top: 32px;
            font-size: 16px;
            line-height: 24px;
        }

        & span {
            color: #f44336;
        }
    }

    &__list {

        & li {
            line-height: 16px;
            padding-left: 32px;

            @media (--tablet) {
                font-size: 16px;
                margin: 15px 0;
                line-height: 24px;
            }

            @media(--desktop) {
                background-size: 16px;
            }
        }
    }
}

.works {
    padding: 40px 0;

    @media(--desktop) {
        padding: 88px 0;
    }


    &__header {
        text-align: center;
    }

    &__subtitle {
        margin: 8px 0 24px 0;

        @media(--desktop) {
            margin: 16px 0 48px 0;
        }
    }

    &__body {

        @media(--desktop) {
            display: flex;
            flex-direction: column;
            padding: 0 48px;
            margin: 0 auto;
            width: 865px;

            & .container__item {
                width: 465px;
                margin-bottom: 0;
                padding-bottom: 48px;
                position: relative;

                @media(--wide) {
                    width: 500px;
                }

                &:nth-child(odd) {
                    align-self: flex-end;

                    &::before {
                        position: absolute;
                        content: "";
                        border: 3px dashed #c2e7f3;
                        left: 30px;
                        height: 100%;
                        z-index: 1;
                    }
                }

                &:nth-child(even) {
                    display: inline-grid;
                    grid-template-columns: 1fr .1fr;
                    grid-template-rows: 1fr;
                    grid-template-areas: "header aside " "body aside";
                    text-align: right;
                    gap: 4px 24px;
                }

                &:nth-child(even)::before {
                    position: absolute;
                    content: "";
                    border: 3px dashed #c2e7f3;
                    left: 92.5%;
                    height: 100%;
                    z-index: 1;

                    @media(--wide) {
                        left: 93%;
                    }
                }

                &:last-child {

                    &::before {
                        content: "";
                        border: none;
                    }
                }

                &-aside {
                    position: relative;
                    z-index: 2;
                }
            }
        }

        @media(--wide) {
            width: 936px;
        }
    }
}

.warranty {
    padding: 40px 0;

    @media(--desktop) {
        padding: 88px 0;
    }

    &__header {
        text-align: center;
    }

    &__body {

        @media(--desktop) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 24px;
            grid-row-gap: 0px;


            & .content-item {
                display: flex;
                flex-direction: column;
                margin-top: 40px;

                &__image {
                    margin-bottom: 24px;

                    & img {
                        width: 88px;
                        height: 88px;
                    }    
                }
            }
        }
    }
}

.section-5 {
    position: relative;
    padding: 40px 0;
    text-align: center;
    background: var(--background);

    @media (--desktop) {
        padding: 88px 0 48px 0;
    }

    &__title {
        margin-bottom: 24px;
        margin: 0 auto;
    }

    &__rating {

        @media (--desktop) {
            margin-bottom: 38px;
        }
    }

    &__buttons {
        display: none;
    
        @media(--desktop) {
            display: block;

            & span {

                & img {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    &__wrap {

        @media(--desktop) {
            max-width: 745px;
            padding: 0;
        }
    }

    & .nav-buttons {

        @media(--desktop) {
            position: relative;
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin: 0 auto;
            bottom: 285px;
            z-index: 5;

            & .prev {
                position: relative;
                right: 20px;
            }

            & .next {
                position: relative;
                left: 20px;
            }
        }
    }

    &__button {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: var(--main-color);
        transition: opacity .3s ease-in-out;
        border: none;

        &:hover {
            opacity: .54;
        }

        & span {
            display: flex;
            justify-content: center;
        }
    }

    &__subtitle {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--second-font-color);
        font-weight: normal;
        opacity: .8;
        text-align: center;
        margin: 8px auto 0;

        @media(--desktop) {
            font-size: 18px;
            line-height: 28px;
            margin: 16px auto 0;
        }
    }

    &__reviews {
        display: flex;
        justify-content: space-between;
    }

    .rating {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        &__stars {
            margin-right: 8px;
            font-size: 0;
            line-height: 1;
        }

        &__star {
            display: inline-flex;
            width: 24px;
            height: 24px;
            background: svg-load("../assets/img/svg/star.svg", fill="#FFBE21") center/80% no-repeat;
        }

        & .star-grey {
            background: svg-load("../assets/img/svg/star.svg", fill="#eeeeee") center/80% no-repeat;
        }

        &__disc {
            font-size: 16px;
            line-height: 24px;
            color: #686e74;
        }
    }

    & .review {
        position: relative;
        height: 100%;
        padding: 16px 16px;
        text-align: left;
        background-color: #fff;
        border-radius: 24px;
        margin-top: 24px;
        cursor: grab;

        @media(--desktop) {
            padding: 16px 40px;
            margin-top: 48px;
        }

        &__head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            padding-right: 20px;
            flex-direction: column;

            @media(--tablet) {
                flex-direction: row;
            }
            

            &-info {
                display: grid;
                grid-template-columns: .4fr 1.6fr;
                grid-template-rows: 1fr 1fr;
                gap: 0 16px;
                grid-template-areas: "avatar name" "avatar date";
                margin-bottom: 6px;

                & .review__avatar {
                    grid-area: avatar;
                    border-radius: 50%;
                    width: auto;
                }

                @media(--tablet) {
                    margin-bottom: 0;
                }
            }

            &-rating {
                align-self: baseline;

                @media(--tablet) {
                    align-self: center;
                }
            }
        }

        &__name {
            grid-area: name;
            display: block;
            margin-bottom: 6px;
            font-family: var(--second-font);
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            color: var(--main-font-color);
            align-self: end;
            margin: 0;

            @media (--desktop) {
                font-size: 18px;
                line-height: 22px;
            }
        }

        &__date {
            grid-area: date;
            position: relative;
            display: flex;
            align-items: center;
            font-size: 12px;
            color: var(--second-font-color);
            align-self: flex-start;

            @media (--desktop) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        &__body {
            height: 100%;
            font-size: 14px;
            line-height: 20px;

            @media (--desktop) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    .owl-carousel .owl-stage-outer {
        position: relative;
        overflow: unset;
    }

    .owl-dots {
        max-width: 72px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .owl-dot {
            margin-top: 16px;
            width: 8px;
            height: 8px;
            background: none;
            border-radius: 50%;
            border: 1px var(--main-color) solid;
            padding: 0;
            font: inherit;
    
            @media(--tablet) {
                width: 12px;
                height: 12px;
            }
    
            @media(--desktop) {
                margin-top: 32px;
            }
        }

        .owl-dot.active {
            background: var(--main-color);
        }
    }
}

.faq {
    padding: 40px 0;

    @media (--desktop) {
        padding: 80px 0 80px;
    }

    &__title {
        margin-bottom: 24px;
        text-align: center;

        @media (--tablet) {
            width: 100%;
            margin-bottom: 48px;
        }
    }

    &__container {

        @media(--tablet) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__cards {
        margin-bottom: 12px;

        @media(--desktop) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 36px;
            grid-row-gap: 0px;

            @media(--wide) {
                grid-column-gap: 72px;
            }
        }
    }

    .faq-card {
        background: #fff;
        margin-bottom: 12px;

        @media(--desktop) {
            margin-bottom: 24px;
        }

        &:last-child {
            border-bottom: none;
        }

        &:hover {

            .faq-card__title {
                color: var(--main-color);

                &::after {
                    background-image: svg-load("../assets/img/svg/ic.svg" , fill="#009ccc");
                }
            }
        }

        &__title {
            position: relative;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;
            transition: all .3s ease-in-out;

            @media(--desktop) {
                font-size: 18px;
                line-height: 28px; 
            }

            &::after {
                content: "";
                left: 6px;
                position: relative;
                display: inline-block;
                background-repeat: no-repeat;
                bottom: -5px;
                right: 28px;
                width: 14px;
                height: 15px;
                background-image: svg-load("../assets/img/svg/ic.svg", fill = #757575);
                transition: background .3s ease-in-out;
            }
        }

        &__body {
            display: none;
            padding-top: 8px;

            &__body-list {
                list-style: list-style-type;
            }

            ul, p:first-of-type {
                margin: 0;
                font-size: 14px;
                line-height: 20px;

                @media(--desktop) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        &.open {

            .faq-card__title {
                color: var(--main-color);

                &::after {
                    transform: rotate(180deg);
                    bottom: 4px;
                    background-image: svg-load("../assets/img/svg/ic.svg", fill = #009ccc);
                }
            }
        }
    }
}

.section-7 {
    overflow: hidden;
    border-top: 1px solid #e0e0e0;

    & .section-7__wrap {
        overflow-x: auto;
    }
}

.payways {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 1024px;
    padding: 15px 0;

    @media (--desktop) {
        padding: 23px 0;
    }

    & .payways__item {
        display: block;
        background-repeat: no-repeat;
        opacity: .5;
        transition: opacity .3s;

        &:hover {
            opacity: 1;
        }
    }

    & .payways__item_text {
        display: none;
        opacity: 1;

        @media (--desktop) {
            display: block;
        }
    }

    & .payways__item_qiwi {
        width: 75px;
        height: 34px;
        background-image: svg-load("../assets/img/svg/qiwi.svg");
        background-size: 75px 34px;
    }

    & .payways__item_visa {
        width: 100px;
        height: 32px;
        background-image: svg-load("../assets/img/svg/cards.svg");
        background-size: 100px 32px;
    }

    & .payways__item_yandex {
        width: 122px;
        height: 24px;
        background-image: svg-load("../assets/img/svg/yandex.svg");
        background-size: 122px 24px;
    }

    & .payways__item_euroset {
        width: 108px;
        height: 18px;
        background-image: svg-load("../assets/img/svg/euroset.svg");
        background-size: 108px 18px;
    }

    & .payways__item_svyznoy {
        width: 111px;
        height: 24px;
        background-image: svg-load("../assets/img/svg/svyznoy.svg");
        background-size: 111px 24px;
    }

    & .payways__item_tinkoff {
        width: 124px;
        height: 34px;
        background-image: svg-load("../assets/img/svg/tinkoff.svg");
        background-size: 124px 34px;
    }

    & .payways__item_webmoney {
        width: 130px;
        height: 32px;
        background-image: svg-load("../assets/img/svg/webmoney.svg");
        background-size: 130px 32px;
    }
}

.footer-2 {
    padding: 15px 0;
    font-size: 10px;
    color: #828282;
    text-align: center;
    background-color: #f4fbfd;
    border-top: 1px solid #c2e7f3;
    border-bottom: 1px solid #c2e7f3;

    @media (--desktop) {
        font-size: 12px;
    }
}

.popup-layer {
    display: none;
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    table-layout: fixed;
    z-index: 999;
    background: rgba(0, 0, 0, 0);
    transition: background .3s;

    & .popup-wrap {
        width: 100%;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-right: 15px;
        padding-left: 15px;

        & .popup {
            opacity: 1;
            display: none;
            position: relative;
            max-width: 640px;
            margin: 0 auto;
            background: #fff;
            padding: 10px 15px;
            border-radius: 20px;
        
            @media(--desktop) {
                padding: 20px;
            }

            &-box {
                font-size: 16px;
                color: #484c52;
                margin: 0 auto;
            
                @media(--desktop) {
                    font-size: 18px;
                }
            }

            &-close {
                position: absolute;
                right: 20px;
                width: 48px;
                height: 48px;
                background: rgb(235,87,87,.1);
                z-index: 1;
                border-radius: 50%;
                cursor: pointer;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    top: 24px;
                    left: 15px;
                    width: 20px;
                    height: 2px;
                    background: rgb(235,87,87,.5);
                    transition: background .3s;
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }

                &:hover {
                    background: rgb(235,87,87,.3);

                    &::before,
                    &::after {
                        background: rgb(235,87,87,.8);
                    }
                }
            }

            &-text {
                margin-right: 65px;
            }
            
        }

        & .callback {
            align-items: center;
            min-height: 64px;
            box-sizing: border-box;

        }
    }
}

.popup-layer.active {
    display: table;
    background: rgba(0, 0, 0, .35);
}
